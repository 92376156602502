

<template>
    <section>
        <b-overlay :show="loading"
                   :variant="variant"
                   :opacity="opacity"
                   :blur="blur"
                   spinner-variant="primary"
                   spinner-type="grow"
                   spinner-large
                   class="loaderPreview"
                   rounded="sm">

            <b-row v-if="(meta.breadcrumb || meta.pageTitle) && dataLoaded"
                   class="content-header">

                <b-col class="content-header-left mb-2"
                       cols="12"
                       md="6">
                    <b-row class="breadcrumbs-top">
                        <b-col cols="12">
                            <h2 class="content-header-title float-left pr-1 mb-0">
                                {{ FunnelName }}
                            </h2>
                            <div class="breadcrumb-wrapper">
                                <b-breadcrumb>
                                    <b-breadcrumb-item to="/">
                                        <feather-icon icon="HomeIcon"
                                                      size="16"
                                                      class="align-text-top" />
                                    </b-breadcrumb-item>
                                    <b-breadcrumb-item active="true">
                                        Contacts
                                    </b-breadcrumb-item>
                                </b-breadcrumb>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>

                <!-- Content Right -->
                <b-col class="content-header-right text-md-right d-md-block d-none mb-1"
                       md="6"
                       cols="12">
                    <b-button :to="'/projectdetail/' + Id"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="button"
                              variant="outline-success">
                        <feather-icon icon="ArrowLeftCircleIcon" size="18" />
                        Go Back
                    </b-button>

                    <download-excel v-if="contactsCount>0"
                                    class="btn btn-outline-primary ml-1"
                                    :data="items"
                                    :fields="Csvfields"
                                    type="csv"
                                    name="Landingly Contacts.xls">
                        <feather-icon icon="FileIcon" size="18" />
                        Download CSV
                    </download-excel>

                </b-col>
            </b-row>

            <b-card>
                <div class="pb-2">
                    <div class="row d-flex justify-content-between flex-wrap">
                        <b-col lg="8">
                            <b-form-group>

                            </b-form-group>
                        </b-col>
                        <b-col lg="4">

                            <b-form-group label="Search" v-if="totalRows>0"
                                          label-cols-sm="3"
                                          label-align-sm="right"
                                          label-size="sm"
                                          label-for="SearchInput"
                                          class="mb-0 align-items-center">
                                <b-input-group size="sm">
                                    <b-form-input id="SearchInput"
                                                  v-model="SearchText"
                                                  type="text"
                                                  placeholder="Type to Search"
                                                  @keyup="searchContact()" />
                                    <b-input-group-append>
                                        <b-button :disabled="!SearchText" @click="clearSearchField">
                                            Clear
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>


                        </b-col>
                    </div>
                </div>

                <b-table striped
                         hover
                         bordered
                         responsive
                         fixed
                         class="position-relative"
                         :current-page="currentPage"
                         :items="items"
                         :fields="fields"
                         :sort-by.sync="sortBy"
                         :sort-desc.sync="sortDesc"
                         @filtered="onFiltered"
                         show-empty>
                    <template v-if="dataLoaded" #empty="scope">
                        Record doesn't exist
                    </template>
                    <template #cell(actions)="data">
                        <b-button size="sm"
                                  v-if="data.item.IsSubscribed == false"
                                  v-b-modal.editContact
                                  type="button"
                                  variant="success"
                                  class="border border-light"
                                  @click="subscribe(data.item.Id, data.item.ProjectId)">
                            Add
                        </b-button>
                        <b-button size="sm"
                                  v-else
                                  v-b-modal.editContact
                                  type="button"
                                  variant="danger"
                                  class="border border-light"
                                  @click="unsubscribe(data.item.Id, data.item.ProjectId)">
                            Remove
                        </b-button>

                        <b-button v-if="hasAdditionalInfo(data.item)"
                                  title="Additional Info"
                                  size="sm"
                                  type="button"
                                  class="btn-icon ml-1"
                                  v-b-modal.contactInfo
                                  variant="flat-success"
                                  @click="showAdditionalInfo(data.item.AddressLineOne,data.item.AddressLineTwo,data.item.City,data.item.State,data.item.PostalCode,data.item.CountryName,data.item.PhoneNo)"
                                  v-b-tooltip.hover.v-success>
                            <feather-icon icon="InfoIcon" size="13" />
                        </b-button>

                        <!--<b-button size="sm"
                          v-if="data.item.IsSubscribed"
                          v-b-modal.projectmodal
                          type="button"
                          variant="success"
                          class="border border-light"
                          @click="subscribe(data.item.Id, data.item.ProjectId)">
                    Yes
                </b-button>
                <b-button size="sm"
                          v-else
                          v-b-modal.projectmodal
                          type="button"
                          variant="default"
                          class="border border-light"
                          @click="subscribe(data.item.Id, data.item.ProjectId)">
                    Yes
                </b-button>-->
                    </template>
                    <template #cell(date)="data">
                        <span>
                            {{
              data.item.RecCreatedDt | moment("DD-MMM-YYYY hh:mm:ss A")
                            }}
                        </span>
                    </template>
                    <template #cell(Status)="data">
                        <label v-if="data.item.IsSubscribed == false">Unsubscribed</label>
                        <label v-if="data.item.IsSubscribed == true">Subscribed</label>
                    </template>

                </b-table>

                <b-card-body class="row d-flex justify-content-between flex-wrap pt-0">
                    <!-- page length -->
                    <b-form-group label="Show"
                                  label-cols="4"
                                  label-align="center"
                                  label-size="sm"
                                  label-for="sortBySelect"
                                  class="text-nowrap mb-md-0 mr-1">
                        <b-form-select id="perPageSelect"
                                       v-model="perPage"
                                       size="sm"
                                       inline
                                       :options="pageOptions" />
                    </b-form-group>
                    <!-- pagination -->
                    <div v-if="totalRows > perPage">
                        <b-pagination v-if="totalRows > 0"
                                      v-model="currentPage"
                                      :total-rows="totalRows"
                                      :per-page="perPage"
                                      first-number
                                      last-number
                                      prev-class="prev-item"
                                      next-class="next-item"
                                      class="mb-0"
                                      @change="loadPage">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </b-card-body>
            </b-card>

            <b-modal id="contactInfo" ref="modal" centered size="lg" title="Additional Information">
                <div v-if="additionalInfoRows.length > 0">
                    <table class="table table-bordered verticle-table">
                        <tbody v-for="(row, index) in additionalInfoRows" :key="index">
                            <tr v-for="(value, key) in row" v-if="value !== null && value !== undefined && value !== ''">
                                <th>
                                    {{ getColumnName(key) }}
                                </th>
                                <td>
                                    {{ value }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <slot></slot>

                <template #modal-footer="{ cancel, ok }">
                    <b-button @click="closeContactInfoModal"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="secondary"
                              type="button">
                        <feather-icon icon="XCircleIcon" size="18" />
                        Close
                    </b-button>
                </template>

            </b-modal>

        </b-overlay>
    </section>
</template>


<script>
    import BCardCode from "@core/components/b-card-code";

    import {
        BCard,
        BCardHeader,
        BCardBody,
        BCardImg,
        BRow,
        BCol,
        BCardText,
        BLink,
        BButton,
        BModal,
        VBModal,
        BFormInput,
        BForm,
        BFormRow,
        BFormValidFeedback,
        BFormInvalidFeedback,
        BFormGroup,
        BTabs,
        BTab,
        BInputGroup,
        BInputGroupAppend,
        BTable,
        BFormSelect,
        BPagination,
        BOverlay,
        VBTooltip,
        BBreadcrumb, BBreadcrumbItem,
    } from "bootstrap-vue";
    import { codeKitchenSink } from "../../table/bs-table/code.js";
    import jwt from "@/auth/jwt/useJwt";
    import Ripple from "vue-ripple-directive";
    import { ValidationProvider, ValidationObserver } from "vee-validate";

    import { $themeConfig } from "@themeConfig";
    import moment from "moment";

    //import axios from 'axios'
    //import qs from "qs"
    /*import { codeTypes } from './code'*/

    export default {
        metaInfo: {
            title: 'Landingly | Project Contacts'
        },
        components: {
            BOverlay,
            BPagination,
            BFormSelect,
            BTable,
            BInputGroup,
            BCardCode,
            BCardText,
            BCard,
            BCardHeader,
            BRow,
            BCol,
            BCardBody,
            BCardImg,
            BLink,
            BModal,
            VBModal,
            BButton,
            BFormInput,
            BForm,
            BFormRow,
            BFormValidFeedback,
            BFormInvalidFeedback,
            BFormGroup,
            BInputGroupAppend,
            BTabs,
            BTab,
            BBreadcrumb, BBreadcrumbItem,
        },
        data() {
            return {
                variant: "light",
                opacity: 0.85,
                blur: "2px",
                FunnelName: "",
                Noactive: false,
                Yesactive: true,
                loading: false,
                meta: {
                    pageTitle: 'Contacts',
                    breadcrumb: [
                        {
                            text: 'Contacts',
                            active: true
                        },
                    ],
                },

                dataLoaded: false,
                additionalInfoRows: [],
                error: null,
                CurrUser: JSON.parse(localStorage.getItem("User_Name")),
                Id: this.$route.params.id,
                WebAPIURL: $themeConfig.app.apiURL,
                perPage: 20,
                pageOptions: [10, 20, 50, 100],
                totalRows: 1,
                currentPage: 1,
                sortBy: "",
                sortDesc: false,
                sortDirection: "asc",
                filter: null,
                SearchText: null,
                filterOn: [],
                infoModal: {
                    id: "info-modal",
                    title: "",
                    content: "",
                },
                fields: [
                    {
                        key: "FirstName", label: "Name", sortable: true,
                        formatter: (value, key, item) => {
                            if (item.FullName !== undefined && item.FullName !== null && item.FullName.trim() !== "") {
                                return item.FullName;
                            } else {
                                return item.FirstName + " " + item.LastName;
                            }
                        },
                    },
                    { key: "Email", label: "Email", sortable: true },
                    { key: "date", label: "Optin Date", sortable: true },
                    { key: "Status", label: "Status", sortable: true },
                    { key: "actions", label: "Action" },
                ],
                Csvfields: {
                    Name: {
                        callback: (row) => {
                            if (row.FullName !== "") {
                                return row.FullName;
                            }
                            else {
                                return `${row.FirstName} ${row.LastName}`
                            }
                        }
                    },
                    Email: "Email",
                    "Optin Date": "RecCreatedDt",
                    Status: {
                        callback: (IsSubscribed) => {
                            if (IsSubscribed == false) {
                                return `Unsubscribed`;
                            } else {
                                return `Subscribed`;
                            }
                        }
                    },
                    "Address One": "AddressLineOne",
                    "Address Two": "AddressLineTwo",
                    "City": "City",
                    "Country Name": "CountryName",
                    "Postal Code": "PostalCode",
                    "State": "State",
                    "Phone No": "PhoneNo"
                },
                contactsCount: 0,
                /* eslint-disable global-require */
                items: [
                    { VendorUserId: "" },
                    { CustomerUserId: "" },
                    { FirstName: "" },
                    { LastName: "" },
                    { FullName: "" },
                    { CompanyId: null },
                    { CompanyName: "" },
                    { Email: "" },
                    { PrimaryEmailId: null },
                    { TotalTags: null },
                    { TotalCampaigns: null },
                    { LeadScore: null },
                    { ContactStatus: null },
                    { TotalCount: null },
                    { ProductId: null },
                    { FunnelName: "" },
                    { ProjectId: null },
                    { ContactSubscriberId: null },
                    { RecCreatedDt: null },
                    { IsSubscribed: true },
                    { Id: 0 },
                ],
                /* eslint-disable global-require */
                status: [
                    {
                        1: "Current",
                        2: "Professional",
                        3: "Rejected",
                        4: "Resigned",
                        5: "Applied",
                    },
                    {
                        1: "light-primary",
                        2: "light-success",
                        3: "light-danger",
                        4: "light-warning",
                        5: "light-info",
                    },
                ],
                codeKitchenSink,
            };
        },
        directives: {
            'b-tooltip': VBTooltip,
            Ripple,
        },
        methods: {
            info(item, index, button) {
                this.infoModal.title = `Row index: ${index}`;
                this.infoModal.content = JSON.stringify(item, null, 2);
                this.$root.$emit("bv::show::modal", this.infoModal.id, button);
            },
            resetInfoModal() {
                this.infoModal.title = "";
                this.infoModal.content = "";
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                //this.totalRows = filteredItems.length
                //this.currentPage = 1
            },
            async getContacts(pagenumber) {
                this.currentPage = pagenumber;
                var self = this;
                self.items = null;
                var searchvalue = this.SearchText;
                var startValue = (pagenumber - 1) * this.perPage;
                var length = this.perPage;

                this.loading = true;
                const access_token = await jwt.getToken();

                console.log("ProjectId : " + this.Id);

                var headers = {
                    Authorization: "bearer " + access_token,
                };
                var axios = require("axios");
                axios
                    .get(
                        "" +
                        this.WebAPIURL +
                        "/api/ContactApi/getConatcts?searchValue=" +
                        searchvalue +
                        "&startValue=" +
                        startValue +
                        "&length=" +
                        length +
                        "&ProjectId=" +
                        this.Id +
                        "&IsServerSide=" +
                        true,
                        { headers }
                    )
                    .then((response) => {
                        self.items = response.data;
                        self.contactsCount = response.data.length;
                        var i;
                        for (i = 0; i < self.items.length; i++) {
                            self.items[i].RecCreatedDt = moment(
                                self.items[i].RecCreatedDt
                            ).format("DD-MMM-YYYY hh:mm:ss A");
                        }

                        if (response.data.length != 0) {
                            self.totalRows = response.data[0].TotalCount;
                        } else {
                            self.totalRows = 0;
                        }
                        self.loading = false;
                    });
            },
            loadPage(pagenumber) {
                this.getContacts(pagenumber);
            },
            async subscribe(Id, ProjectId) {
                // this.Noactive=false;
                // this.Yesactive=true;

                var self = this;

                this.loading = true;
                const access_token = await jwt.getToken();

                var headers = {
                    Authorization: "bearer " + access_token,
                };
                var axios = require("axios");
                axios
                    .get("" + this.WebAPIURL + "/api/ContactApi/MarkUnsubscribeResubscribe?ContactId=" +
                        Id + "&IsSubscribed=" + true + "&ProjectId=" + ProjectId,
                        { headers }
                    )
                    .then((response) => {
                        self.getContacts(self.currentPage);
                        self.loading = false;
                    });
            },
            async unsubscribe(Id, ProjectId) {
                var self = this;

                this.loading = true;
                const access_token = await jwt.getToken();

                var headers = {
                    Authorization: "bearer " + access_token,
                };
                var axios = require("axios");
                axios
                    .get("" + this.WebAPIURL + "/api/ContactApi/MarkUnsubscribeResubscribe?ContactId=" +
                        Id + "&IsSubscribed=" + false + "&ProjectId=" + ProjectId,
                        { headers }
                    )
                    .then((response) => {
                        self.getContacts(self.currentPage);
                        self.loading = false;
                    });
            },

            hasAdditionalInfo(item) {
                return (
                    item.AddressLineOne != null && item.AddressLineOne !== '' ||
                    item.AddressLineTwo != null && item.AddressLineTwo !== '' ||
                    item.City != null && item.City !== '' ||
                    item.State != null && item.State !== '' ||
                    item.PostalCode != null && item.PostalCode !== '' ||
                    item.PhoneNo != null && item.PhoneNo !== '' ||
                    item.CountryName != null && item.CountryName !== ''
                );
            },

            showAdditionalInfo(AddressLineOne, AddressLineTwo, City, State, PostalCode, CountryName, PhoneNo) {
                this.additionalInfoRows = [];

                this.additionalInfoRows.push({
                    AddressLineOne,
                    AddressLineTwo,
                    City,
                    State,
                    PostalCode,
                    CountryName,
                    PhoneNo
                });
            },

            getColumnName(key) {
                // Customize column labels based on keys
                switch (key) {
                    case 'AddressLineOne':
                        return 'Address One';
                    case 'AddressLineTwo':
                        return 'Address Two';
                    case 'PostalCode':
                        return 'Postal Code';
                    case 'CountryName':
                        return 'Country Name';
                    default:
                        return key; // Use the key itself if no custom label is defined
                }
            },

            closeContactInfoModal() {
                this.$bvModal.hide("contactInfo");
            },

            clearSearchField() {
                this.SearchText = '';
                this.searchContact();
            },

            async searchContact(searchvalue) {
                var pagenumber = this.currentPage;
                var self = this;
                var searchvalue = this.SearchText;
                var startValue = (pagenumber - 1) * this.perPage;
                var length = this.perPage;

                this.loading = true;
                const access_token = await jwt.getToken();

                console.log("ProjectId : " + this.Id);

                var headers = {
                    Authorization: "bearer " + access_token,
                };
                var axios = require("axios");
                axios
                    .get("" + this.WebAPIURL + "/api/ContactApi/getConatcts?searchValue=" +
                        searchvalue + "&startValue=" + startValue + "&length=" + length + "&ProjectId=" + this.Id + "&IsServerSide=" + true,
                        { headers }
                    )
                    .then((response) => {
                        self.items = response.data;
                        self.contactsCount = response.data.length;
                        if (response.data.length != 0) {
                            self.totalRows = response.data[0].TotalCount;
                        } else {
                            self.totalRows = 1;
                        }
                        this.modalShow = true;
                        self.loading = false;
                    });
            },
            async projectContacts() {
                var self = this;

                this.loading = true;
                const access_token = await jwt.getToken();

                var headers = {
                    Authorization: "bearer " + access_token,
                };
                var axios = require("axios");
                axios
                    .get(
                        "" + this.WebAPIURL + "/api/ContactApi/ProjectContacts?Id=" + this.Id,
                        { headers }
                    )
                    .then((response) => {
                        if (response.data != "Not Found") {
                            self.FunnelName = response.data;
                        } else {
                            self.FunnelName = response.data;
                        }

                        self.loading = false;
                        self.dataLoaded = true;
                    });
            },
        },
        mounted() {
            this.getContacts(1);
            this.projectContacts();
            /*document.getElementsByClassName("card-header")[0].style.display = "none";*/
        },
        created() {
           
        },
        beforeCreate() {
            
        },
        destroyed() {
            //document.querySelector('a[href*="/contacts"]').parentNode.classList.remove("active");
        }
    };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

